<template>
  <div class="card-board">
    <b-link :to="{ name: 'ShowBoard', params: { id: board.id } }">
      <div class="board__body">
        <img
          v-if="board.style && board.style.background_image"
          :src="board.style.background_image[0].path"
          :alt="board.title"
          class="board__image"
        />
        <img
          v-else
          src="@/assets/images/pages/Rectangle 846.png"
          :alt="board.title"
          class="board__image"
        />
        <!-- {{board.image}} -->
        <div class="board__content">
          <h4 class="board__name">
            {{ board.title }}
          </h4>
        </div>
      </div>
    </b-link>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },

  props: ["board"],
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
