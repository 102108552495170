<template>
  <loading v-if="isLoading" />
  <div v-else class="board-list">
    <div class="show-course-boards">
      <card v-for="board in boards" :key="board.id" :board="board" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/classrooms/boards/showCourses/boards/layout/card.vue";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "BoardList",
  components: {
    Card,
    Loading,
  },
  data() {
    return {
      boards: null,
      isLoading: true,
    };
  },
  created() {
    this.$http
      .get(`guardian/courses/${this.$route.params.id}?enrolled=true`)
      .then((res) => {
        if (res.status === 200) {
          this.boards = res.data.data.boards;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style lang="scss" src="./_boardList.scss"></style>
